import React, { useState } from "react";
import TermsConditionDialog from "./dialog";
import TCDocURLs from "./data";

const TermConditions = () => {

  const [pdfURL, setPDFURL] = useState(null);
  const [selectedTC, setSelectedTC] = useState(null);

  const handleDialogOpen = (type) => {
    let currentURL = TCDocURLs.get(type);

    setPDFURL(currentURL);
    setSelectedTC(type === "MLP" ? "Marketing Launchpad" : "Business & Sales Leadership Launchpad");
    const termCondDialog = document.querySelector("#terms-condition-dialog");

    if(termCondDialog) {
      termCondDialog.showModal();
    }
  };

  const handleDialogClose = () => {
    setPDFURL(null);
    setSelectedTC(null);
    const termCondDialog = document.querySelector("#terms-condition-dialog");
    
    if(termCondDialog) {
      termCondDialog.close();
    }
  };

  return (
    <>
      <section className="terms-conditions-container">
        <h1 className="terms-conditions-heading">
                    Terms & Conditions:
        </h1>
        {/* <ul>
          <li>
            <button className="terms-cond-dialog-btns" onClick={() => handleDialogOpen('MLP')}>
                                Marketing Launchpad
            </button>
          </li>
          <li>
            <button className="terms-cond-dialog-btns" onClick={() => handleDialogOpen('BASL')}>
                                Business & Sales Leadership Launchpad
            </button>
          </li>
        </ul> */}
        <h3 className="tandc-text">All payments are in reference to the T&Cs shared in the offer email</h3>
      </section>
      <TermsConditionDialog 
        handleDialogClose={handleDialogClose} 
        pdfURL={pdfURL}
        selectedTC={selectedTC}
      />
    </>
  );
};

export default TermConditions;